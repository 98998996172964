@keyframes appear {
  0% {
    @apply -mb-64;
    clip-path: circle(0% at 0 0);
  }
  50% {
    clip-path: circle(0% at 0 0);
  }
  to {
    clip-path: circle(150% at 0 0);
    @apply -mb-0;
  }
}

@keyframes fadeOut {
  from {
    @apply opacity-100;
  }
  to {
    @apply opacity-0;
  }
}

.animateIn {
  animation: appear 1.25s ease;
}

.chip {
  @apply bg-white/5 px-3 py-2 flex items-center justify-center  whitespace-nowrap;
}

.expertplus {
  background-color: var(--expert-plus) !important;
}

.expert {
  background-color: var(--expert) !important;
}

.hard {
  background-color: var(--hard) !important;
}

.normal {
  background-color: var(--normal) !important;
}

.easy {
  background-color: var(--easy) !important;
}
